@import '../../assets/scss/colors.scss';

@import '../../assets/scss/mixins/typography.scss';
@import '../../assets/scss/mixins/button.scss';
@import '../../assets/scss/mixins/labels.scss';
@import '../../assets/scss/mixins/toast.scss';
@import '../../assets/scss/mixins/tooltip.scss';
@import '../../assets/scss/mixins/input.scss';
@import '../../assets/scss/mixins/checkbox.scss';
@import '../../assets/scss/mixins/dropdown.scss';
@import '../../assets/scss/mixins/cards.scss';
@import '../../assets/scss/mixins/table.scss';
@import '../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.content-editor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1000px;
  margin-inline: auto;

  .top-box {
    @include round-card;

    .info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px solid $manatte6;
      padding-bottom: 1em;
      border-radius: 0;
      gap: 1em;

      > * {
        display: flex;
        gap: 0.5em;
        align-items: center;
        width: min(30vw, 400px);

        h3 {
          display: flex;
          flex-direction: column;

          :first-child {
            font-size: 0.75rem;
            color: $manatte1;
          }
          :last-child {
            font-size: 0.875rem;
            font-weight: 700;
          }
        }
      }

      .test-strategy {
        border-right: 1px solid $manatte6;

        .test-targets {
          height: 56px;
          width: 112px;
        }
      }
      .product {
        img {
          width: 56px;
          height: 56px;
          border-radius: 0.5rem;
          object-fit: cover;
        }
      }
    }

    .content {
      h4 {
        font-size: 1rem;
        font-weight: 700;
        margin-block: 1em;
      }
    }
  }

  .compare-view {
    .heading {
      @include h2($oxfordBlue);
      padding: 1rem;
      border-bottom: 1px solid $oxfordBlue9;
    }

    .compare {
      display: grid;
      grid-template-columns: 50% 50%;
      word-break: break-word;
    }
    > * {
      .version {
        border-right: 1px solid $manatte8;

        .info {
          display: flex;
          align-items: center;
          padding: 1rem;
          border-bottom: 1px solid $manatte8;

          h4 {
            margin: 0;
          }

          h4 {
            font-size: 1em;
            font-weight: 700;
          }

          .preview-button {
            margin-left: auto;
          }
        }
        .content {
          padding: 1rem;
        }
      }
    }
  }
}
